import React, { useState } from 'react';
import styles from './cargoReference.module.css';

const Drake = '/images/Cargo Reference/Thumbnails/Standard/Drake.webp';
const RSIAegis = '/images/Cargo Reference/Thumbnails/Standard/aegis.webp';
const Crusader = '/images/Cargo Reference/Thumbnails/Standard/Crusader.webp';
const MISC = '/images/Cargo Reference/Thumbnails/Standard/misc.webp';
const Origin = '/images/Cargo Reference/Thumbnails/Standard/Origin.webp';
const Other = '/images/Cargo Reference/Thumbnails/Standard/others.webp';
const MainImageSrc = '/images/Cargo Reference/other_cargo.webp';

const DrakeMain = '/images/Cargo Reference/drake_cargo.webp';
const MiscMain = '/images/Cargo Reference/misc_cargo.webp'; 
const RSIAegisMain = '/images/Cargo Reference/rsi_aegis_cargo.webp';
const CrusaderMain = '/images/Cargo Reference/crusader_cargo.webp';
const OriginMain = '/images/Cargo Reference/origin_cargo.webp';
const otherMain = '/images/Cargo Reference/other_cargo.webp';

function CargoGrids() {
    const [mainImage, setMainImage] = useState(MainImageSrc);

    const handleThumbnailClick = (newImageSrc) => {
        setMainImage(newImageSrc);
    };

    return (
        <>
            <section className={styles['whole-page']}>
            <section className={styles["primary"]}>
                <div className={styles["primary-background"]}></div>

                <div className={styles["primary-content"]}>
                    <div className={styles["course"]}>
                        <div className={styles["main-image-container"]}>
                            <img id={styles["main-image"]} src={mainImage} alt="Main Image" />
                        </div>

                        <div className={styles["thumbnail-container"]}>
                            <div className={styles["thumbnails"]}>
                                <img
                                    className={styles["thumbnail"]}
                                    src={Drake}
                                    alt="Drake"
                                    onClick={() => handleThumbnailClick(DrakeMain)}
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={RSIAegis}
                                    alt="Aegis"
                                    onClick={() => handleThumbnailClick(RSIAegisMain)}
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={Crusader}
                                    alt="Crusader"
                                    onClick={() => handleThumbnailClick(CrusaderMain)}
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={MISC}
                                    alt="MISC"
                                    onClick={() => handleThumbnailClick(MiscMain)}  
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={Origin}
                                    alt="Origin"
                                    onClick={() => handleThumbnailClick(OriginMain)}
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={Other}
                                    alt="Others"
                                    onClick={() => handleThumbnailClick(otherMain)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className={styles["footer"]}>
                <div className={styles["source"]}>
                    <div className={styles["source-location"]}>
                        <p>Source: <a href="https://robertsspaceindustries.com/community-hub/post/3-22-ship-cargo-grid-reference-sheets-fTX3VoNJNa2qQ" target='_blank'>Star Citizen Community Hub, by Erec</a></p>
                    </div>
                </div>
            </footer>
           </section>
        </>
    );
}

export default CargoGrids;
