import styles from '../Home Page/Home.module.css'


function HomePage() {
    return (
        <>



<body>
<section class={styles['home-page']}>
        <section class={styles["content"]}>
   

        <div class={styles["text-box"]}>
            <h1>Ratjack's Home Page</h1>
            <p>Welcome to my site!</p>
            <a href="https://discord.gg/Mqn8UCuXSm" target="_blank" class={styles["hero-btn"]} id={styles["discord-link"]}>
                <i class="fa-brands fa-discord"></i> Join the Discord
            </a>
            <a href="https://twitch.tv/ratjackofficial" target="_blank" class={styles["hero-btn"]} id={styles["twitch-link"]}>
                <i class="fa-brands fa-twitch"></i> Twitch
            </a>
            <a href="https://youtube.com/ratjack" target="_blank" class={styles["hero-btn"]} id={styles["youtube-link"]}>
                <i class="fa-brands fa-youtube"></i> YouTube
            </a>
        </div>


    </section>

    <section class={styles["video-box"]}>
        <div class={styles["media"]}>
            <div class={styles["iframe-container"]}>
                <iframe id={styles["youtube-video" ]}
                src="https://www.youtube.com/embed/tvs4mnZzVnQ?si=1AM3r5Vz5TmxAZrZ"
                    width="560"
                    height="315"
                    title="YouTube video player" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    </section>


</section>




</body>


        </>
    )
}

export default HomePage;