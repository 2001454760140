import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import '../Components/Header.css?v=1.0.0';
import { Link } from 'react-router-dom';
const RatLogo = '/images/rat_logo.webp';


function Header() {
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  const toggleSubmenu = (event) => {
    event.preventDefault();
    // Use jQuery to slide toggle the submenu
    $('.sub-menu').stop(true, true).slideToggle(300);
    $('.dropdown').toggleClass('rotate') // 300 ms for sliding
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showHeader, setShowHeader] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    // Show header if scrolling up, hide if scrolling down
    setShowHeader(scrollPosition > currentScrollPos || currentScrollPos < 300);
    setScrollPosition(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPosition]);


  return (
    <>
      <div className="menu-btn" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
      </div>

      <div className={`side-bar ${isSidebarActive ? 'active' : ''}`}>
        <div className="close-btn" onClick={toggleSidebar}>
          <i className="fas fa-times"></i>
        </div>
        <div className="menu">
          <div className="item">
            <a href="/">
              <i className="fa-solid fa-house"></i>Home
            </a>
          </div>

          <div className="item">
            <a className="sub-btn" onClick={toggleSubmenu}>
              <i className="fa-solid fa-thumbtack"></i>Star Citizen Resources
              <i className="fas fa-angle-right dropdown"></i>
            </a>
            <div className="sub-menu" style={{ display: 'none' }}> {/* Hide it initially */}
            <a href="/Medical" className="sub-item">Medical Information</a>
              <a href='/Salvage' className='sub-item'>Salvage Guide</a>
              <a href='/CargoReference' className='sub-item'>Cargo Grids</a>
              <a href="https://robertsspaceindustries.com/enlist?referral=STAR-32MD-WWF3" target="_blank" className='sub-item'>SC Referral</a>
              <a href='/Parliament' className='sub-item'>The Parliament</a>
              <a href='/OtherTools' className='sub-item'>SC Tools</a>
              {/* Additional submenu items */}
            </div>
          </div>

          <div className="item">
            <a href="/Equipment">
              <i className="fa-solid fa-toolbox"></i>Equipment
            </a>
          </div>
        </div>
      </div>

      <div className={`logo ${showHeader ? "visible " : "hidden"}`}>
        <div className="image">
          <a href="/">
            <img src={RatLogo} alt='Ratjack Logo' />
          </a>
        </div>
      </div>

       <div className={`top-nav-wrapper ${showHeader ? "visible" : "hidden"}`}>
      <div className="top-nav">
        
        <div className="nav-content">
         <Link to='/' className='item'>Home</Link>
          
          <span className="separator">|</span>
          <div className="dropdown">
            <a href="#" className="dropbtn">SC Resources</a>
            <div className="dropdown-content">
            <Link to='/Medical' className='item'>Medical Guide</Link>
            <Link to='/Salvage' className='item'>Salvage Guide</Link>
            <Link to='/CargoReference' className='item'>Cargo Grids</Link>
            <a href="https://robertsspaceindustries.com/enlist?referral=STAR-32MD-WWF3" target="_blank">SC Referral </a>
            <Link to='/Parliament' className='item'>The Parliament</Link>
            {/* <Link to='Player-Lookup' className='item'>Player Lookup</Link> */}
            <Link to='/OtherTools' className='item'>Other Tools</Link>
              
              {/* Additional dropdown items */}
            </div>
          </div>
        <span className="separator">|</span>
          <Link to="/Equipment" className='item'>Equipment</Link>
        
        </div>
      </div>
      </div>
    </>
  );
}

export default Header;
