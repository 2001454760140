import styles from '../Medical/medical.module.css?v=1.0'
const pamphletMedicalImage = '/images/pamphlet_medical.webp';


function Medical() {
    return (
       <>
       <body>



<section class={styles["primary"]}>
    <div class={styles["primary-background"]}></div>
    <div class={styles["primary-content"]}>
        <div class={styles["course"]}>
        <img src={pamphletMedicalImage} alt="Pamphlet Medical" />


            
            

        </div>


    </div>
</section>

</body>

       
       </>
        
  
     
    )
}

export default Medical;