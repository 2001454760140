import styles from "./other_resources.module.css?v=1.0";
import { useEffect } from "react";
const erkul = "/images/erkul.webp";
const verseguide = "/images/verseguide.webp";
const snare = "/images/snare.webp";
const tradetools = "/images/tradetools.webp";
const cstone = "/images/cstone.webp";
const reshade = "/images/reshade.webp";
const scwiki = "/images/scwiki.webp";
const fleetview = "/images/fleetview.webp";
const starship42 = "/images/starship42.webp";
const sccharacters = "/images/sccharacters.webp";
const uexcorp = "/images/uexcorp.webp";
const ccugame = "/images/ccugame.webp";
const subliminal = "/images/subliminal.webp";
const blank = "/images/blank.webp";

function OtherTools() {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.visible);
                }
            });
        });

        const columns = document.querySelectorAll(`.${styles.column}`);

        if (window.innerWidth > 500) {
            columns.forEach((column) => {
                observer.observe(column);
            });
        } else {
            columns.forEach((column) => {
                column.classList.add(styles.visible);
            });
        }

        return () => {
            columns.forEach((column) => {
                observer.unobserve(column);
            });
        };
    }, []);

    return (
        <>
            <section className={styles["primary-content"]}>
                <section className={styles["primary2"]}>
                    <div className={styles["row2"]}>
                        <div className={styles["column"]}>
                            <a href="https://www.erkul.games" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${erkul})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>Ekrul Games</div>
                                <div className={styles["description"]}>
                                    Erkul.games is a star citizen loadout tool. You can use this
                                    tool to outfit your ships in a web browser to see how it
                                    impacts their stats. You can also determine the cost of
                                    aftermarket parts as well as where they can be purchased.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a href="https://www.verseguide.com" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${verseguide})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>VERSE GUIDE</div>
                                <div className={styles["description"]}>
                                    Verse Guide is a great tool to open up each planet and see
                                    information about it. This tool lists information about each
                                    outpost, length of days, temperature, and so much more.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a href="https://snareplan.dolus.eu/" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${snare})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>DOLUS SNARERPLAN</div>
                                <div className={styles["description"]}>
                                    Snare Plan is a tool for those who like to prey on the others
                                    currently out engaging in trade, mining, or other activities.
                                    This tool will allow you to determine the best spot to set up
                                    a snare.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a href="https://www.sc-trade.tools/home" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${tradetools})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>SC Trade Tools</div>
                                <div className={styles["description"]}>
                                    SC Trade Tools contains information on trade routes to help
                                    you plan the most profitable trades. The site also contains
                                    mining and salvage information. Much of the information is
                                    kept up to date by users.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles["primary2"]}>
                    <div className={styles["row2"]}>
                        <div className={styles["column"]}>
                            <a href="https://finder.cstone.space/" target="_blank">
                                <div className={styles["overflow-box"]}>
                                    <div
                                        className={styles["background"]}
                                        style={{ backgroundImage: `url(${cstone})` }}
                                    ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>
                                    Cornerstone Universal Item Finder
                                </div>
                                <div className={styles["description"]}>
                                    Cornerstone is a site that provides tons of information on
                                    items in the game. Some of the most helpful information is
                                    where to purchase certain items/ships and how much they'll
                                    cost.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a
                                href="https://www.youtube.com/watch?v=fMfh5vkTj9I" target="_blank">
                                <div className={styles["overflow-box"]}>
                                    <div
                                        className={styles["background"]}
                                        style={{ backgroundImage: `url(${reshade})` }}
                                    ></div>
                                </div>

                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>
                                    Reshade Guide by Karolinger
                                </div>
                                <div className={styles["description"]}>
                                    Reshade is a tool that provides a sort of filter/overlay to
                                    your game to allow you to adjust colors, brightness, etc. This
                                    is a great video guide on how to get that setup and running.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a href="https://starcitizen.tools/" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${scwiki})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>SC Wiki</div>
                                <div className={styles["description"]}>
                                    Star Citizen Wiki provides tons of information on ships,
                                    characters, lore, equipment, componnents, and so much more.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a href="https://hangar.link" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${fleetview})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>
                                    Star Jump Fleet Viewer
                                </div>
                                <div className={styles["description"]}>
                                    The Star Jump Fleet Viewer is a 2d fleet rendering system. You
                                    can either manually add ships or upload a JSON file to get
                                    your fleet into a picture. For a json file I recommend the
                                    extension Hangar Xplor.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles["primary2"]}>
                    <div className={styles["row2"]}>
                        <div className={styles["column"]}>
                            <a href="https://starship42.com/" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${starship42})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>StarShip 42</div>
                                <div className={styles["description"]}>
                                    StarsShip 42 is a fleet modeler similar to Star Jump's Fleet
                                    Viewer. The big difference here is that this one is more of a
                                    3d modeling system.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a
                                href="https://www.star-citizen-characters.com/"
                                target="_blank"
                            >
                                <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${sccharacters})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>
                                    Star Citizen Characters
                                </div>
                                <div className={styles["description"]}>
                                    This is a place for people to share their custom characters
                                    that they've made in the character customizer.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a href="https://uexcorp.space/" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${uexcorp})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>UEX Corp</div>
                                <div className={styles["description"]}>
                                    UEX Corps site is heavily focused on providing information for
                                    traders. Here you will find lots of great information relating
                                    to commodities.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]}>
                            <a href="https://ccugame.app/" target="_blank">
                               
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${ccugame})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>CCU Game App</div>
                                <div className={styles["description"]}>
                                    CCU Game is a great app to use when you're looking to spend
                                    new money and want to maximizes savings on a ship by chaining
                                    warbonds. See my video on CCU chaining{" "}
                                    <a
                                        href="https://www.youtube.com/watch?v=40AoukWhL48&t=3s"
                                        target="_blank"
                                    >
                                        here
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles["primary2"]}>
                    <div className={styles["row2"]}>
                        <div className={styles["column"]} id="float">
                            <a href="https://subliminal.gg/bindings/" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background"]}
                                    style={{ backgroundImage: `url(${subliminal})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}>
                                    Subliminal's VKB Keybinds
                                </div>
                                <div className={styles["description"]}>
                                    Every patch lately seems to require new keybinds. Subliminal
                                    constantly puts out updated recommended VKB binds with charts
                                    for ease of use. These are best used with Joystick Gremlin and
                                    Subliminal has videos on how to set this up.
                                </div>
                            </div>
                        </div>
                        <div className={styles["column"]} id="float">
                            <a href="https://subliminal.gg/bindings/" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background2"]}
                                    style={{ backgroundImage: `url(${blank})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}></div>
                                <div className={styles["description"]}></div>
                            </div>
                        </div>
                        <div className={styles["column"]} id="float">
                            <a href="https://uexcorp.space/" target="_blank">
                            <div className={styles["overflow-box"]}>
                                <div
                                    className={styles["background2"]}
                                    style={{ backgroundImage: `url(${blank})` }}
                                ></div>
                                </div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}></div>
                                <div className={styles["description"]}></div>
                            </div>
                        </div>
                        <div className={styles["column"]} id="float">
                            <a href="" target="_blank">
                                <div
                                    className={styles["background2"]}
                                    style={{ backgroundImage: `url(${blank})` }}
                                ></div>
                            </a>

                            <div className={styles["information"]}>
                                <div className={styles["content-box"]}></div>
                                <div className={styles["description"]}>
                                    <a href="" target="_blank"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}

export default OtherTools;
