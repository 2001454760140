import styles from './Player-Lookup.module.css';
import { useState } from 'react';
import axios from 'axios'
import { DateTime } from 'luxon'


function PlayerLookup() {
    const [playerName, setPlayerName] = useState("");

    const handleInputChange = (event) => {
        setPlayerName(event.target.value);
    };

    const handleSubmit = async () => {

        let response = await axios.get(
            `https://api.starcitizen-api.com/hsAANaJtqcjC1JS7hvUZTRToyBxQRVbY/v1/cache/user/${playerName}`
        );
        let data = response.data.data;

        if (data == null) {
            // If cache is empty, check live version
            response = await axios.get(
                `https://api.starcitizen-api.com/hsAANaJtqcjC1JS7hvUZTRToyBxQRVbY/v1/live/user/${playerName}`
            );
            data = response.data.data;
        }

        response = await axios.get(
            'http://162.248.100.128:3000/api/player-find'
        )


        const displayName = data.profile.display
        const playerhandle = data.profile.handle
        const enlisted = data.profile.enlisted.split('T')[0]
        const [year, month, day] = enlisted.split('-')
        const PreenlistedDate = `${month}-${day}-${year}`
        const enlistedDate =  DateTime.fromFormat(PreenlistedDate, 'MM-dd-yyyy').toLocaleString(DateTime.DATE_FULL);
        const ueenumber = data.profile.id
        const primaryOrg = data.organization.name
        const playerpicture = data.profile.image
        const bio = data.profile.bio
        const url = data.profile.page.url
        const language = data.profile.fluency
        const orgpiclink = data.organization.image
        const playerlocation = data.profile.location

        const givenDate = DateTime.fromFormat(PreenlistedDate, 'MM-dd-yyyy');
        const today = DateTime.now();
        const diff = today.diff(givenDate, ['years', 'months']);
        const years = Math.floor(diff.years);
        const months = Math.floor(diff.months % 12); // Adjust for overflow in months

        let otherOrgs = data.affiliation
            .map(org => org.name)
            .filter(name => name && name.trim().length > 0) // Filter out empty or whitespace-only names
            .join('\n');




        //alert(`Player lookup for: ${enlisted}`);
        // You can replace the alert with any logic you need for handling the submitted player name











    };

    return (
        <div className={styles["primary"]}>
            <div className={styles["primary-background"]}></div>
            <div className={styles["primary-content"]}>
                <input
                    type="text"
                    className={styles["input-box"]}
                    value={playerName}
                    onChange={handleInputChange}
                    placeholder="Enter player name"
                />
                <button
                    className={styles["submit-button"]}
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}

export default PlayerLookup;
